import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'
import {checkToken} from '../../utils/CheckAuth/CheckAuth'


const initialState = {
    SingleRideImpactData:null,
    CoporateSectorImpactData:null,
    ListOfDashboards:[],
    APIDashboardCredentials:"",
    rideType:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',

}



export const createDashboard = createAsyncThunk('BankImpact/createDashboard',
async(formData, thunkAPI)=>{
    try{

        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/insights/bank/addDashboard`
        const response = await axios.post(API_URL,formData ,config)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        return thunkAPI.rejectWithValue(message)
    }
}
)



export const SingleRideImpact = createAsyncThunk('BankImpact/SingleRideImpact',
async(formData, thunkAPI)=>{
    try{
        const { rideDistance ,carMileage, carType, passengers, rideTime } =  formData

        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }

        const API_URL = `${process.env.REACT_APP_SERVER_URL}/insights/bank/SingleRideImpact`
        const response = await axios.post(API_URL, { rideDistance ,carMileage, carType, passengers, rideTime },config)

        return response.data
    }catch(error){

        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        console.log("lllasdasdlklkl")


        return thunkAPI.rejectWithValue(message)
    }
}
)

export const CoporateSectorImpact = createAsyncThunk('BankImpact/CoporateSectorImpact',
async(dashboardId, thunkAPI)=>{
    try{

        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
 
        
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/insights/bank/mydashboard`
        const response = await axios.post(API_URL,{dashboardId, productId:"price_1OCkjkDfioMqmKOUWvS3Kaul"},config)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        return thunkAPI.rejectWithValue(message)
    }
}
)

export const getDashboardList = createAsyncThunk('BankImpact/getDashboardList',
async(_, thunkAPI)=>{
    try{

        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/insights/bank/getDashboardList`
        const response = await axios.get(API_URL,config)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        return thunkAPI.rejectWithValue(message)
    }
}
)
export const getAPIsavedCredentials = createAsyncThunk('BankImpact/getAPIsavedCredentials',
    async(secret_key, thunkAPI)=>{
        try{
    
            const token =await thunkAPI.getState().auth.user.token
            const config = {
                headers:{
                    Authorization:`Bearer ${token}`
                }
            }
            const API_URL = `${process.env.REACT_APP_SERVER_URL}/insights/bank/saveApiCredentials`
            const response = await axios.post(API_URL,secret_key,config)
            return response.data
        }catch(error){
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            if(message==="Session Expired, please login again to continue"){
                checkToken()
            }
            return thunkAPI.rejectWithValue(message)
        }
    }
    )

export const BankdeleteDashboardItem = createAsyncThunk('BankImpact/BankdeleteDashboardItem',
async(dashboardId, thunkAPI)=>{
    try{

        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/insights/bank/deleteDashboard/${dashboardId}`
        const response = await axios.delete(API_URL,config)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        return thunkAPI.rejectWithValue(message)
    }
}
)



export const BankImpactSlice = createSlice({
    name:'BankImpactSlice',
    initialState,
    reducers:{
        reset:(state)=>{
            state.SingleRideImpactData = null
            state.CoporateSectorImpactData=null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
        resetStatusKeys:(state)=>{
            console.log("reset chla")
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''

        },

    },
    extraReducers:(builder) => {
        builder
        // ...............SingleRideImpact..................SingleRideImpact.................SingleRideImpact...................SingleRideImpact.........SingleRideImpact
        .addCase(SingleRideImpact.pending, (state)=>{
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        })
        .addCase(SingleRideImpact.fulfilled, (state, action)=>{
            state.SingleRideImpactData =  action.payload.SingleRideImpactData
            state.rideType =  action.payload.rideType
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
            console.log(action.payload)
        })
        .addCase(SingleRideImpact.rejected, (state, action)=>{
            state.SingleRideImpactData =  null
            state.rideType =  ""
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)
        })


        // ...............CoporateSectorImpact..................CoporateSectorImpact.................CoporateSectorImpact...................CoporateSectorImpact.........CoporateSectorImpact
        .addCase(CoporateSectorImpact.pending, (state)=>{
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        })
        .addCase(CoporateSectorImpact.fulfilled, (state, action)=>{
            if(action.payload.success===true){
                state.CoporateSectorImpactData =  action.payload.userData
                state.CoporateSectorImpactData.companyName =  action.payload.dashboard_name
                state.CoporateSectorImpactData.company_logo_url =  action.payload.company_logo_url
                state.rideType =  action.payload.rideType
            }
            state.isError = false
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
            console.log(action.payload,"payload")
        })
        .addCase(CoporateSectorImpact.rejected, (state, action)=>{
            console.log(action.payload," rreeerejected")
            state.CoporateSectorImpactData =  null
            state.rideType =  ""
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)

        })


        // ...............createDashboard..................createDashboard.................createDashboard...................createDashboard.........createDashboard
        .addCase(createDashboard.pending, (state)=>{
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        })
        .addCase(createDashboard.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(createDashboard.rejected, (state, action)=>{
            state.rideType =  ""
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)
        })



        // ...............getDashboardList..................getDashboardList.................getDashboardList...................getDashboardList.........getDashboardList
        .addCase(getDashboardList.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        })
        .addCase(getDashboardList.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            if(action.payload.success){
                state.ListOfDashboards = action.payload.ListOfDashboards
            }

        })
        .addCase(getDashboardList.rejected, (state, action) => {
            state.rideType = ""
            state.isLoading = false
            state.isError = true
            state.ListOfDashboards = []
            state.message = action.payload
            toast.error(action.payload)
        })
        // ...............getAPIsavedCredentials..................getAPIsavedCredentials.................getDashboardList...................getDashboardList.........getDashboardList
        .addCase(getAPIsavedCredentials.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        })
        .addCase(getAPIsavedCredentials.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            if(action.payload.success){
                console.log(action.payload,"action.payloadaction.payload")
                state.APIDashboardCredentials = action.payload.secret_key
            }

        })
        .addCase(getAPIsavedCredentials.rejected, (state, action) => {
            state.rideType = ""
            state.isLoading = false
            state.isError = true
            state.APIDashboardCredentials = ""
            state.message = action.payload
            toast.error(action.payload)
        })
        
        // ...............BankdeleteDashboardItem..................BankdeleteDashboardItem.................BankdeleteDashboardItem...................BankdeleteDashboardItem.........BankdeleteDashboardItem
        .addCase(BankdeleteDashboardItem.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        })
        .addCase(BankdeleteDashboardItem.fulfilled, (state, action) => {
            state.isLoading = false
            if(action.payload.success){
                state.ListOfDashboards=action.payload.ListOfDashboards
                state.isSuccess = true
            }
            state.message = action.payload.message
            toast.info(action.payload.message)
            console.log(action.payload)
        })
        .addCase(BankdeleteDashboardItem.rejected, (state, action) => {
            state.rideType = ""
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)
        })
        

            
    }


    
})

export const {reset,resetStatusKeys} = BankImpactSlice.actions
export default BankImpactSlice.reducer