import * as React from 'react';
import Box from '@mui/material/Box';
import './Drawer.css'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {AiOutlineDoubleLeft} from 'react-icons/ai'
import {BsStars} from 'react-icons/bs'
import {AiFillStar} from 'react-icons/ai'
import { Link } from 'react-router-dom';
export default function SwipeableTemporaryDrawer() {
  const pathname = window.location.pathname

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      
      <ListItemButton>
            <ListItemIcon> <AiFillStar/></ListItemIcon>
            <Link to="/Mobility-Sector">
              <ListItemText primary={"Mobility Sector"} />
              </Link>
      </ListItemButton>
      <ListItemButton>
            <ListItemIcon> <AiFillStar/></ListItemIcon>
            <Link to="/Banking-Sector">
              <ListItemText primary={"Bank Sector"} />
              </Link>
      </ListItemButton>

   
        {[{title:"",link:null}, {title:'My Dashboard',link:"/Mobility-Sector/my-dashboards"},{title:"My API Section",link:"/Mobility-Sector/my-api-section"}, {title:"Demo Dashboard",link:"/Mobility-Sector/my-dashboard-demo"}, {title:"Subscriptions",link:"/subscribe"}, ''].map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton>
            <ListItemIcon>
              {(index !== 0 & index !== 5 ) ? <BsStars/> :null}
              </ListItemIcon>
              <Link to={item.link}>
              <ListItemText primary={item.title} /></Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["", {title:'My Account',link:"/my-account"},""].map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton>
              <ListItemIcon>
              {(index !== 0 & index !== 2) ? <BsStars/> :null}
              </ListItemIcon>
              <Link to={item.link}>
              <ListItemText primary={item.title} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className='Drawer_container'>
        <React.Fragment key={"left"}>
          <Button onClick={toggleDrawer("left", true)}><AiOutlineDoubleLeft className={pathname==="/" ? 'Burger_material_UI_Icon_white' : 'Burger_material_UI_Icon_black'}/></Button>
          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
        </React.Fragment>
    </div>
  );
}
