import React from 'react'
import './Header.css'
import Drawer from '../Drawer/Drawer'
import {FiChevronDown} from 'react-icons/fi'
import { Link } from 'react-router-dom'
import {logout} from '../../features/Auth/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'


const Header = () => {
  const dispatch = useDispatch()
  const {user} =  useSelector((store)=>store.auth)
  const pathName =window.location.pathname

  return (
    <div className='Header_container'>
        <div className='Header'>

        <div className='Header_Drawer_parent'>
            <Drawer />
          <Link className="header_left" to='/'>
         

          
          <h5>FORI</h5>
          <div className='header_left_img'>
          <h5>INSIGHTS</h5>
          </div>
         
          </Link>
            </div>
       

        <div className="header_right">
            {user ?
             <button className='btn' onClick={()=>dispatch(logout())}>Logout</button>:
            <>
            <Link to="/login" className={pathName==='/login'?"active":"non_active"}>Sign in</Link>
            </>
           
            }

<div className="header_dropdown">
    <button className="dropbtn flex_row_center">Business Impact<FiChevronDown/></button>
    <div className="header_dropdown-content">
    <Link to='/Mobility-Sector' className={pathName==='/Mobility-Sector'?"active":"non_active"}>Mobility Sector </Link>
    <Link to='/Power-Sector' className={pathName==='/Power-Sector'?"active":"non_active"}>Power Sector </Link>
    <Link to='/Finance-Sector' className={pathName==='/Finance-Sector'?"active":"non_active"}>Finance Sector </Link>
    <Link to='/Banking-Sector' className={pathName==='/Banking-Sector'?"active":"non_active"}>Bank Sector </Link>
    </div>
  </div>

  <div className="header_dropdown">
    <button className="dropbtn flex_row_center">Personal Impact<FiChevronDown/></button>
    <div className="header_dropdown-content">
    <Link to='/individual' className={pathName==='/individual'?"active":"non_active"}>Annual Impact</Link>
    <Link to='/Personal-Ride' className={pathName==='/Personal-Ride'?"active":"non_active"}>Personal Ride</Link>
    </div>
  </div>

  <div className="header_dropdown">
    <button className="dropbtn flex_row_center">Activities<FiChevronDown/></button>
    <div className="header_dropdown-content">
    <Link to='/Grow-Your-Tree' className={pathName==='/Grow-Your-Tree'?"active":"non_active"}>Grow Your Tree</Link>
    </div>
  </div>

      <Link to='/Your-Awards' className={pathName==='/Your-Awards'?"active":"non_active"}>Awards & Achievements</Link>

        </div>
        </div>

    </div>
  )
}

export default Header