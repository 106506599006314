import React, {useEffect, Suspense, lazy } from "react";
import { Routes, Route } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './pages/PersonalSingleRide/SingleRide.css'


import Home from './pages/Home/Home';
import Loader from './components/Loader/Loader'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import ScrollToTop from './utils/ScrollToTop/ScrollToTop'
import MobileNav from './components/MobileNav/MobileNav';
import GoBackButton from './components/GoBackButton/GoBackButton';



const Power = lazy(() => import("./pages/Power/Power"));

const Finance = lazy(() => import("./pages/Finance/Finance"));
const FinanceImpactResult = lazy(() => import("./pages/Finance/FinanceImpactResult"));

const Mobility = lazy(() => import("./pages/Mobility/IntroPage/Mobility"));

const IndividualImpact = lazy(() => import("./pages/IndividualImpact/IndiviualImpactPage"));
const IndividualImpactResult = lazy(() => import("./pages/IndividualImpact/IndviualImpactResults/IndiviualImpactResults"));
const SingleRide = lazy(() => import("./pages/PersonalSingleRide/SingleRide"));
const SingleRideDemoPage = lazy(() => import("./pages/PersonalSingleRide/PersonalRideDemoPage/PersonalRideDemoPage"));
const DashboardConnectionGuide = lazy(() => import("./pages/Mobility/ConnectionGuide/DashboardConnectionGuide/DashboardConnectionGuide"));
const APIConnectionGuide = lazy(() => import("./pages/Mobility/ConnectionGuide/APIConnectionGuide/APISectionGuide"));
const MyDashboardDemo = lazy(() => import("./pages/Mobility/DemoPage/MyDashboardDemo"));
const DashboardConnectionPage = lazy(() => import("./pages/Mobility/ConnectionPage/DashboardConnectionPage"));
const MyDashbaord = lazy(() => import("./pages/Mobility/MyDashboard/MyDashbaord"));
const MyAPISection = lazy(() => import("./pages/Mobility/MyAPISection/MyAPISection"));
const DashboardListPage = lazy(() => import("./pages/Mobility/DashboardListPage/DashboardListPage"));
//////////////////////////////////////////////// banking app/////////////////////////////////////////////////////////////////////////////
const Banking = lazy(() => import("./pages/Banking/IntroPage/Banking"));
const BankingMyDashboardDemo = lazy(() => import("./pages/Banking/DemoPage/MyDashboardDemo"));
const BankDashboardConnectionGuide = lazy(() => import("./pages/Banking/ConnectionGuide/DashboardConnectionGuide/DashboardConnectionGuide"));
const BankAPIConnectionGuide = lazy(() => import("./pages/Banking/ConnectionGuide/APIConnectionGuide/APISectionGuide"));
const BankDashboardConnectionPage = lazy(() => import("./pages/Banking/ConnectionPage/DashboardConnectionPage"));
const BankMyDashbaord = lazy(() => import("./pages/Banking/MyDashboard/MyDashbaord"));
const BankMyAPISection = lazy(() => import("./pages/Banking/MyAPISection/MyAPISection"));
const BankDashboardListPage = lazy(() => import("./pages/Banking/DashboardListPage/DashboardListPage"));
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Login = lazy(() => import("./pages/Auth/Login"));
const ForgotPassword = lazy(() => import("./pages/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/Auth/Resetpassword"));
const Register = lazy(() => import("./pages/Auth/Register"));
const Verify = lazy(() => import("./pages/Auth/Verify"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const UnderConstruction = lazy(() => import("./utils/UnderConstruction/UnderConstruction"));


const Price = lazy(() => import("./pages/PaymentPages/PricingPage/PricingPage"))
const PaymentSuccessPage = lazy(() => import("./pages/PaymentPages/PaymentSucessPage/PaymentSuccessPage"))
const SubscriptionSuccessPage = lazy(() => import("./pages/PaymentPages/SubscriptionSuccessPage/SubscriptionSuccessPage"))


const GrowYourTreePage = lazy(() => import("./pages/GrowYourTreePage/GrowYourTreePage"));
const Awards = lazy(() => import("./pages/Awards/Awards"));


const WhoWeAre = lazy(() => import("./pages/AboutPages/WhoWeAre/WhoWeAre"));
const WhatWeNeed = lazy(() => import("./pages/AboutPages/WhatWeNeed/WhatWeNeed"));
const WhatYouWillGet = lazy(() => import("./pages/AboutPages/WhatYouWillGet/WhatYouWillGet"));

const NotFound = lazy(() => import("./utils/NotFoundPage/NotFoundPage"));


const notAlowedUrls=["verify-account", "/"]

function App() {
const location=useLocation();
  useEffect(() => {
    AOS.init({});
  }, []);


  return (
    <>
      {notAlowedUrls.includes(location.pathname) ? null : <Header />}
     <MobileNav/>
     <GoBackButton/>
   

      <div style={{ paddingTop: "0rem" }}>
        
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/register" element={<Suspense fallback={<Loader/>}>< Register /></Suspense>} />
          <Route path="/verify-account" element={<Suspense fallback={<Loader/>}>< Verify /></Suspense>} />
          <Route path="/login" element={<Suspense fallback={<Loader/>}>< Login /></Suspense>} />
          <Route path="/forgot-Password" element={<Suspense fallback={<Loader/>}>< ForgotPassword /></Suspense>} />
          <Route path="/reset-Password" element={< ResetPassword />} />
          <Route path="/contact" element={<Suspense fallback={<Loader/>}>< Contact /></Suspense>} />


          <Route path="/subscribe" element={<Suspense fallback={<Loader/>}>< Price /></Suspense>} />
          <Route path="/Payment-Successful" element={<Suspense fallback={<Loader/>}>< PaymentSuccessPage /></Suspense>} />
          <Route path="/Subscription-Successful" element={<Suspense fallback={<Loader/>}>< SubscriptionSuccessPage /></Suspense>} />

          <Route path="/my-account" element={<Suspense fallback={<Loader/>}>< UnderConstruction /></Suspense>} />
          <Route path="/who-we-are" element={<Suspense fallback={<Loader/>}>< WhoWeAre /></Suspense>} />
          <Route path="/what-we-need" element={<Suspense fallback={<Loader/>}>< WhatWeNeed /></Suspense>} />
          <Route path="/what-will-you-get" element={<Suspense fallback={<Loader/>}>< WhatYouWillGet /></Suspense>} />

          <Route path="/individual" element={<Suspense fallback={<Loader/>}>< IndividualImpact /></Suspense>} />
          <Route path="/individual/result" element={<Suspense fallback={<Loader/>}>< IndividualImpactResult /></Suspense>} />

          <Route path="/Mobility-Sector" element={<Suspense fallback={<Loader/>}><Mobility /></Suspense>} />
          <Route path="/Personal-Ride" element={<Suspense fallback={<Loader/>}>< SingleRide /></Suspense>} />
          <Route path="/Personal-Ride-demo" element={<Suspense fallback={<Loader/>}>< SingleRideDemoPage /></Suspense>} />
          <Route path="/Mobility-Sector/my-dashboard-guide" element={<Suspense fallback={<Loader/>}><DashboardConnectionGuide/></Suspense>} />
          <Route path="/Mobility-Sector/my-api-section-guide" element={<Suspense fallback={<Loader/>}><APIConnectionGuide/></Suspense>} />
          <Route path="/Mobility-Sector/my-dashboard-demo" element={<Suspense fallback={<Loader/>}><MyDashboardDemo/></Suspense>} />
          <Route path="/Mobility-Sector/my-dashboard-connection" element={<Suspense fallback={<Loader/>}><DashboardConnectionPage/></Suspense>} />
          <Route path="/Mobility-Sector/my-dashboards" element={<Suspense fallback={<Loader/>}><DashboardListPage/></Suspense>} />
          <Route path="/Mobility-Sector/my-dashboards/:token" element={<Suspense fallback={<Loader/>}><MyDashbaord/></Suspense>} />
          <Route path="/Mobility-Sector/my-api-section" element={<Suspense fallback={<Loader/>}><MyAPISection/></Suspense>} />


          {/* banking sector */}
          <Route path="/Banking-Sector" element={<Suspense fallback={<Loader/>}><Banking /></Suspense>} />
          <Route path="/Banking-Sector/my-dashboard-demo" element={<Suspense fallback={<Loader/>}><BankingMyDashboardDemo/></Suspense>} />
          <Route path="/Banking-Sector/my-dashboard-guide" element={<Suspense fallback={<Loader/>}><BankDashboardConnectionGuide/></Suspense>} />
          <Route path="/Banking-Sector/my-api-section-guide" element={<Suspense fallback={<Loader/>}><BankAPIConnectionGuide/></Suspense>} />
          {/* <Route path="/Banking-Sector/my-dashboard-demo" element={<Suspense fallback={<Loader/>}><BankMyDashboardDemo/></Suspense>} /> */}
          <Route path="/Banking-Sector/my-dashboard-connection" element={<Suspense fallback={<Loader/>}><BankDashboardConnectionPage/></Suspense>} />
          <Route path="/Banking-Sector/my-dashboards" element={<Suspense fallback={<Loader/>}><BankDashboardListPage/></Suspense>} />
          <Route path="/Banking-Sector/my-dashboards/:token" element={<Suspense fallback={<Loader/>}><BankMyDashbaord/></Suspense>} />
          <Route path="/Banking-Sector/my-api-section" element={<Suspense fallback={<Loader/>}><BankMyAPISection/></Suspense>} />


          {/* /////////////////////////////// */}


          <Route path="/Grow-Your-Tree" element={<Suspense fallback={<Loader/>}><GrowYourTreePage/></Suspense>} />
          <Route path="/Your-Awards" element={<Suspense fallback={<Loader/>}><Awards/></Suspense>} />

          <Route path="/Power-Sector" element={<Suspense fallback={<Loader/>}><Power/></Suspense>} />

          <Route path="/Finance-Sector" element={<Suspense fallback={<Loader/>}><Finance/></Suspense>} />
          <Route path="/Finance-Sector/result" element={<Suspense fallback={<Loader/>}>< FinanceImpactResult /></Suspense>} />


          <Route path="*" element={<Suspense fallback={<Loader/>}><NotFound /></Suspense>} />

        </Routes>
        <Footer />
        <ScrollToTop />
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
